import React, { useCallback } from "react";

import { useRouter } from "next/router";

import Button from "@/components/interactives/Button";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useAuthStore from "@/stores/auth";
import usePlaySessionStore from "@/stores/playSession";
import useWebSocketStore from "@/stores/webSocket";

import { QUIZ_VOTEMODE_ID } from "@/constants";

const SPRITE_PHONE_SHADOW_STYLE = {
	backgroundImage: "url('/images/sprites/start-game-phone.png')",
	backgroundPosition: "0% 0%",
	backgroundSize: "300% 100%",
};

const SPRITE_PHONE_LIGHT_STYLE = {
	backgroundImage: "url('/images/sprites/start-game-phone.png')",
	backgroundPosition: "50% 0%",
	backgroundSize: "300% 100%",
};

const SPRITE_PHONE_DARK_STYLE = {
	backgroundImage: "url('/images/sprites/start-game-phone.png')",
	backgroundPosition: "100% 0%",
	backgroundSize: "300% 100%",
};

const SPRITE_HAND_SHADOW_STYLE = {
	backgroundImage: "url('/images/sprites/start-game-hand.png')",
	backgroundPosition: "0% 0%",
	backgroundSize: "300% 100%",
};

const SPRITE_HAND_LIGHT_STYLE = {
	backgroundImage: "url('/images/sprites/start-game-hand.png')",
	backgroundPosition: "50% 0%",
	backgroundSize: "300% 100%",
};

const SPRITE_HAND_DARK_STYLE = {
	backgroundImage: "url('/images/sprites/start-game-hand.png')",
	backgroundPosition: "100% 0%",
	backgroundSize: "300% 100%",
};

function Sprite({ type = "phone", mirror = false, className, wrapperClassName, innerClassName }) {
	let spriteStyle = null;
	let spriteShadowStyle = null;
	if (type === "phone") {
		if (mirror) {
			spriteStyle = SPRITE_PHONE_DARK_STYLE;
		} else {
			spriteStyle = SPRITE_PHONE_LIGHT_STYLE;
		}
		spriteShadowStyle = SPRITE_PHONE_SHADOW_STYLE;
	}
	if (type === "hand") {
		if (mirror) {
			spriteStyle = SPRITE_HAND_DARK_STYLE;
		} else {
			spriteStyle = SPRITE_HAND_LIGHT_STYLE;
		}
		spriteShadowStyle = SPRITE_HAND_SHADOW_STYLE;
	}

	return (
		<div className={tailwindCascade("absolute", "inset-0", "transform", className)}>
			<div
				className={tailwindCascade(
					"absolute",
					"left-0",
					"bottom-0",
					"w-full",
					"h-full",
					"transform",
					wrapperClassName
				)}
			>
				<div
					className={tailwindCascade(
						"absolute",
						"transform",
						"bottom-0",
						"-translate-x-1/2",
						"origin-[50%_100%]",
						innerClassName
					)}
				>
					<div
						className={tailwindCascade(
							"relative",
							"left-0",
							"bottom-0",
							"w-full",
							"pb-[150%]",
							"transform",
							"translate-x-[3%]",
							"translate-y-[9%]",
							{
								"-scale-x-100": mirror,
							}
						)}
					>
						<div className="absolute w-full h-full" style={spriteShadowStyle}></div>
					</div>
					<div
						className={tailwindCascade(
							"absolute",
							"left-0",
							"bottom-0",
							"w-full",
							"pb-[150%]",
							"transform",
							{
								"-scale-x-100": mirror,
							}
						)}
						style={spriteStyle}
					/>
				</div>
			</div>
		</div>
	);
}

export default function EditorialStartGame({ className, setShowCreatePage = null }) {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const isGuestUser = useAuthStore((state) => state.isGuestUser);
	const router = useRouter();
	const setNewPlaySession = usePlaySessionStore((state) => state.setNewPlaySession);
	const isHostingActive = useWebSocketStore((state) => state.connected);

	const onClick = useCallback(() => {
		if (isAuthenticated || isGuestUser) {
			if (!isHostingActive) {
				setNewPlaySession(true);
			}
			router.push(`/play/${QUIZ_VOTEMODE_ID}/`);
		} else {
			if (setShowCreatePage) {
				setShowCreatePage(true, QUIZ_VOTEMODE_ID);
			}
		}
	}, [isAuthenticated, isGuestUser, isHostingActive, router, setNewPlaySession, setShowCreatePage]);

	return (
		<div className="flex w-full flex-col md:flex-row items-center justify-center bg-petrol-dark p-8 rounded-xl gap-8">
			<h2 className="md:text-2xl lg:text-4xl text-2xl font-black leading-none text-center text-white">
				{trans("Can't decide? Let players vote")}
			</h2>
			<Button
				elementType="button"
				border={true}
				color="answer3"
				className="md:h-14 whitespace-nowrap md:px-6 md:w-auto md:py-0 px-6 font-bold text-black min-w-[7rem] md:min-w-[12rem]"
				onClick={onClick}
			>
				{trans("Start vote mode")}
			</Button>
		</div>
	);
}
