import clamp from "lodash/clamp";
import isString from "lodash/isString";

import {
	ANSWER_TIME_EXTRA_LONG,
	ANSWER_TIME_EXTRA_SHORT,
	ANSWER_TIME_LONG,
	ANSWER_TIME_NORMAL,
	ANSWER_TIME_SHORT,
	IS_REVEAL_TYPE_TIMELAPSE,
} from "@/constants";

import {
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
} from "@/app-constants.mjs";
import trans from "./trans";

export const ANSWER_TIMES = {
	[ANSWER_TIME_EXTRA_SHORT]: 5,
	[ANSWER_TIME_SHORT]: 10,
	[ANSWER_TIME_NORMAL]: 20,
	[ANSWER_TIME_LONG]: 45,
	[ANSWER_TIME_EXTRA_LONG]: 80,
};

export const ANSWER_TIMES_NO_QUESTION = {
	[ANSWER_TIME_EXTRA_SHORT]: 1,
	[ANSWER_TIME_SHORT]: 3,
	[ANSWER_TIME_NORMAL]: 6,
	[ANSWER_TIME_LONG]: 12,
	[ANSWER_TIME_EXTRA_LONG]: 30,
};

export const ANSWER_TIMES_LOCATION = {
	[ANSWER_TIME_EXTRA_SHORT]: 15,
	[ANSWER_TIME_SHORT]: 35,
	[ANSWER_TIME_NORMAL]: 50,
	[ANSWER_TIME_LONG]: 75,
	[ANSWER_TIME_EXTRA_LONG]: 90,
};

const STREET_VIEW_EXTRA_TIME = 10;
const TYPE_ANSWER_EXTRA_TIME = 10;
const TIMELAPSE_EXTRA_TIME = 5;

export function getRevealTime({ answerTime, slideType }) {
	if (slideType === SLIDE_TYPE_INFO_SLIDE) {
		return ANSWER_TIMES_NO_QUESTION[answerTime || ANSWER_TIME_NORMAL];
	} else if (slideType === SLIDE_TYPE_LOCATION) {
		return ANSWER_TIMES_LOCATION[answerTime || ANSWER_TIME_NORMAL];
	} else if (slideType === SLIDE_TYPE_REORDER) {
		return ANSWER_TIMES[answerTime || ANSWER_TIME_NORMAL] * 1.5;
	} else {
		return ANSWER_TIMES[answerTime || ANSWER_TIME_NORMAL];
	}
}

export function getAnswerTime({ answerTime, slideType, revealType, mediaSource }) {
	return getRevealTime({ answerTime, slideType }) + getExtraAnswerTime({ slideType, revealType, mediaSource });
}

function getExtraAnswerTime({ slideType, revealType, mediaSource }) {
	let t = 0;

	if (slideType === SLIDE_TYPE_TYPE_ANSWER) {
		t = t + TYPE_ANSWER_EXTRA_TIME;
	}

	if (IS_REVEAL_TYPE_TIMELAPSE(revealType)) {
		t = t + TIMELAPSE_EXTRA_TIME;
	}

	if (mediaSource?.startsWith("street/")) {
		t = t + STREET_VIEW_EXTRA_TIME;
	}

	return t;
}

export function secondsToText(t, round = false, long = false) {
	t = Math.round(t);
	if (!t) {
		return null;
	}

	if (round) {
		let m = Math.round(t / 60);
		if (m === 0) {
			const m = Math.floor(t / 60);
			const s = t - m * 60;
			if (s === 0) {
				return "";
			}
			if (long) {
				return trans("%d sec", s);
			} else {
				return trans("%ds", s);
			}
		}
		if (long) {
			return trans("%d min", m);
		} else {
			return trans("%dm", m);
		}
	}

	const m = Math.floor(t / 60);
	const s = t - m * 60;

	if (m === 0) {
		if (long) {
			return trans("%d sec", s);
		} else {
			return trans("%ds", s);
		}
	} else if (s === 0) {
		if (long) {
			return trans("%d min", m);
		} else {
			return trans("%dm", m);
		}
	} else {
		if (long) {
			return trans("%d min, %d sec", Math.floor(t / 60), t % 60);
		} else {
			return trans("%dm%ds", Math.floor(t / 60), t % 60);
		}
	}
}

const DELAY = 0.05;

export function getPointsProgressFromAnswerProgress(t) {
	return clamp(-DELAY + (1 + DELAY) * t, 0, 1);
}

export function getPointsFromPointsProgress(t) {
	return 1000 - 600 * t;
}

export const getPointsFromAnswerProgress = (t) => getPointsFromPointsProgress(getPointsProgressFromAnswerProgress(t));

export const getReadingTime = (str) => {
	if (!isString(str)) {
		return 0;
	}

	const numWords = str.trim().split(/\s+/).length;

	// Assume 200 words per minute
	const minutes = numWords / 200;
	const seconds = minutes * 60;

	return seconds;
};
