import React, { useCallback } from "react";

import { useRouter } from "next/router";

import useAuthStore from "@/stores/auth";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import Button from "@/components/interactives/Button";

const BACKGROUND_STYLE = {
	backgroundImage: "url('/images/backgrounds/background-create-quiz.svg')",
	backgroundPosition: "50% 50%",
	backgroundSize: "84px auto",
};

const LEFT_ICONS = [
	["/images/icons/create-quiz-youtube.svg", trans("Youtube")],
	["/images/icons/create-quiz-map.svg", trans("Map")],
	["/images/icons/create-quiz-camera.svg", trans("Camera")],
];

const RIGHT_ICONS = [
	["/images/icons/create-quiz-paint.svg", trans("Paint")],
	["/images/icons/create-quiz-gif.svg", trans("Gif")],
	["/images/icons/create-quiz-photo.svg", trans("Photo")],
];

export default function EditorialCreateQuiz({ className, setShowCreatePage = null }) {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const router = useRouter();

	const onClick = useCallback(() => {
		if (isAuthenticated) {
			router.push("/edit/new/");
		} else {
			if (setShowCreatePage) {
				setShowCreatePage(true, null, false, false, "/edit/new/");
			}
		}
	}, [isAuthenticated, router, setShowCreatePage]);

	return (
		<div
			className={tailwindCascade(
				"bg-petrol-lighter",
				"rounded-xl",
				"relative",
				"w-full",
				"overflow-hidden",
				"mb-4",
				"z-0",
				className
			)}
		>
			<div
				className="justify-evenly py-11 text-fff relative flex flex-row items-center w-full h-full"
				style={BACKGROUND_STYLE}
			>
				{LEFT_ICONS.map(([src, alt], i) => (
					<img
						key={i}
						src={src}
						alt={alt}
						className={tailwindCascade(
							"animate-yoyo hidden w-16 h-16 text-center justify-evenly items-center text-base font-bold",
							{
								"sm:flex": i === 2,
								"md:flex": i === 1,
								"lg:flex": i === 0,
							}
						)}
						style={{ animationDelay: `${(-1.2 / 6) * i}s` }}
					/>
				))}
				<Button color="answer2" border={true} className="min-h-[56px] text-black" onClick={onClick}>
					{trans("Create your own quiz")}
				</Button>
				{RIGHT_ICONS.map(([src, alt], i) => (
					<img
						key={i}
						src={src}
						alt={alt}
						className={tailwindCascade(
							"animate-yoyo hidden w-16 h-16 text-center justify-evenly items-center text-base font-bold",
							{
								"sm:flex": i === 0,
								"md:flex": i === 1,
								"lg:flex": i === 2,
							}
						)}
						style={{ animationDelay: `${(-1.2 / 6) * (i + 3)}s` }}
					/>
				))}
			</div>
		</div>
	);
}
