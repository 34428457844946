import React, { useCallback, useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import useAuthStore from "@/stores/auth";
import { apiGetQuizzes } from "@/api/quiz";
import EditorialCreateQuiz from "@/components/EditorialCreateQuiz";
import QuizScrollContainer from "@/components/QuizScrollContainer";
import { tailwindCascade } from "@/helpers/tailwindCascade";

export default function EditorialUserLibrary({
	className,
	initialInView = false,
	setShowCreatePage = null,
	hideQuizItemNew = false,
	...props
}) {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

	const [quizzes, setQuizzes] = useState(null);
	const [totalQuizzes, setTotalQuizzes] = useState(null);

	const [section, updateSection] = useImmer({
		name: props?.section?.name,
		type: props?.section?.type,
		parameter: props?.section?.parameter,
		tags: props?.section?.tags,
	});
	useEffect(
		() =>
			updateSection((draft) => {
				draft.name = props?.section?.name;
				draft.type = props?.section?.type;
				draft.parameter = props?.section?.parameter;
				draft.tags = props?.section?.tags;
			}),
		[]
	);

	const [showMyLibrary, setShowMyLibrary] = useState(null);

	useEffect(() => {
		if (isAuthenticated) {
			const showMyLibrary = JSON.parse(localStorage.getItem("showMyLibrary"));

			if (showMyLibrary !== null) {
				setShowMyLibrary(showMyLibrary);
			} else {
				setShowMyLibrary(true);
			}
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated && showMyLibrary !== null) {
			let mounted = true;
			setQuizzes(null);

			(async () => {
				try {
					const countOnly = !showMyLibrary;

					const response = await apiGetQuizzes(
						{ sortKey: "updatedAt", sortOrder: "desc", limit: 12, countOnly: countOnly },
						true,
						false
					);

					if (mounted) {
						setTotalQuizzes(response.total);
						setQuizzes(response.quizzes);
					}
				} catch (error) {
					console.error(error);
				}
			})();
			return () => {
				mounted = false;
			};
		}
	}, [isAuthenticated, showMyLibrary]);

	return (
		<div className="flex flex-col">
			<div className="relative w-full h-full">
				{showMyLibrary !== null && totalQuizzes && totalQuizzes > 0 ? (
					<QuizScrollContainer
						quizzes={quizzes}
						total={totalQuizzes}
						title={section.name}
						setShowCreatePage={setShowCreatePage}
						initialInView={initialInView}
						isMyLibrary={true}
						showCreateQuiz={false}
						titleLink={"/user/library/"}
						hideQuizItemNew={true}
						showMyLibrary={showMyLibrary}
						onTogglePreview={async () => {
							const newSetting = !showMyLibrary;
							localStorage.setItem("showMyLibrary", newSetting);
							setShowMyLibrary(newSetting);
						}}
					/>
				) : /*(
					<div className="w-full">
						{section.name && (
							<div className="flex flex-row items-center justify-start pt-2 pb-2 space-x-8">
								<h2 className="font-sans text-xl font-black tracking-normal text-black capitalize">
									{section.name}
								</h2>
							</div>
						)}
						<div className="relative w-full min-h-[178px]">
							<EditorialCreateQuiz
								className="absolute w-full h-full"
								setShowCreatePage={setShowCreatePage}
							/>
						</div>
					</div>
				) */ null}
			</div>
		</div>
	);
}
