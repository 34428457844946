import produce, { freeze } from "immer";
import { useState, useCallback, useRef } from "react";

export default function useRefImmer(initialValue) {
	const [value, updateValue] = useState(
		freeze(typeof initialValue === "function" ? initialValue() : initialValue, true)
	);
	const refValue = useRef(value);
	return [
		value,
		useCallback((update) => {
			const value = typeof update === "function" ? produce(refValue.current, update) : freeze(update);
			refValue.current = value;
			updateValue(value);
		}, []),
		refValue,
	];
}
