import React, { useState, useEffect, useRef } from "react";

import QuizScrollContainer from "@/components/QuizScrollContainer";
import NavigationCategoryIcon from "@/components/NavigationCategoryIcon";
import EditorialStartGame from "@/components/EditorialStartGame";
import EditorialCreateQuiz from "@/components/EditorialCreateQuiz";

import TagList from "@/components/TagList";
import useAuthStore from "@/stores/auth";
import cloneDeep from "lodash/cloneDeep";
import { apiCreateEditorialSection, apiDeleteEditorialSection, apiUpdateEditorialSection } from "@/api/editorial";
import { apiGetQuiz } from "@/api/quiz";
import trans from "@/helpers/trans";
import Selector from "./interactives/Selector";
import Button from "./interactives/Button";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import EditorialUserLibrary from "./EditorialUserLibrary";

const NUMBER_OF_INITIAL_IN_VIEW_ITEMS = 4;

const EDITORIAL_TYPE_STATIC = "static";
const EDITORIAL_TYPE_MODIFIED = "modified";
const EDITORIAL_TYPE_CREATED = "created";
const EDITORIAL_TYPE_PUBLISHED = "published";
const EDITORIAL_TYPE_NAME = "name";
const EDITORIAL_TYPE_POPULAR = "popular";
const EDITORIAL_TYPE_MOSTPLAYED = "mostPlayed";
const EDITORIAL_TYPE_BESTRATING = "bestRating";
const EDITORIAL_TYPE_GAMEBANNER = "gameBanner";
const EDITORIAL_TYPE_CREATEBANNER = "createBanner";
const EDITORIAL_TYPE_EASYBYRATINGLASTMONTH = "EasyByRatingLastMonth";
const EDITORIAL_TYPE_HARDBYRATINGLASTMONTH = "HardByRatingLastMonth";
const EDITORIAL_TYPE_WITHTAGBYPUBLISHDATE = "tag";
const EDITORIAL_TYPE_WITHTAGMOSTRELEVANT = "tag2";
const EDITORIAL_TYPE_RANDOM = "random";
const EDITORIAL_TYPE_AI = "ai";
const EDITORIAL_USER_LIBRARY = "userLibrary";

export default function Editorial({
	showCategoryName = false,
	showTags = false,
	setShowCreatePage = null,
	className,
	...props
}) {
	const [editorial, setEditorial] = useState(props.editorial);
	useEffect(() => void setEditorial(props.editorial), [props.editorial]);

	const name = editorial?.category?.name;
	const tags = editorial?.category?.tags;
	const sections = editorial?.sections;
	const tag = editorial?.category?.tag;

	return (
		<>
			<div className={tailwindCascade("flex flex-col pb-4", className)}>
				{showCategoryName && name && (
					<h1 className="pt-7 text-black-50 flex flex-row items-center pb-1 mb-4 space-x-4 font-sans text-4xl font-bold">
						<span className="block">{name}</span>
						<span className="block">
							<NavigationCategoryIcon name={name} className="w-8 h-8" />
						</span>
					</h1>
				)}

				{showTags && tags && tags.length > 0 && (
					<TagList
						tags={(tag ? [{ ...tag, displayName: trans("All") }] : []).concat(tags)}
						categorySlug={editorial?.category?.slug}
					/>
				)}

				<div className="flex flex-col">
					{(sections || []).map((section, index) => {
						if (section.type === EDITORIAL_TYPE_GAMEBANNER) {
							return (
								<div key={index} className="md:mb-5 relative mt-3 mb-3">
									<EditorialStartGame setShowCreatePage={setShowCreatePage} />
								</div>
							);
						}

						if (section.type === EDITORIAL_TYPE_CREATEBANNER) {
							return (
								<div key={index} className="relative">
									<EditorialCreateQuiz setShowCreatePage={setShowCreatePage} />
								</div>
							);
						}

						if (section.type === EDITORIAL_USER_LIBRARY) {
							return (
								<div key={index} className="relative">
									<EditorialUserLibrary
										initialInView={index < NUMBER_OF_INITIAL_IN_VIEW_ITEMS}
										setShowCreatePage={setShowCreatePage}
										section={section}
										hideQuizItemNew={true}
									/>
								</div>
							);
						}

						if (section.quizzes && section.quizzes.length) {
							return (
								<div key={index} className="relative">
									<QuizScrollContainer
										quizzes={section.quizzes}
										title={section.name}
										setShowCreatePage={setShowCreatePage}
										initialInView={index < NUMBER_OF_INITIAL_IN_VIEW_ITEMS}
										sectionId={section.type === EDITORIAL_TYPE_STATIC && section.id}
										section={section}
										category={editorial?.category}
									/>
								</div>
							);
						}
					})}
				</div>
			</div>
		</>
	);
}
