import React from "react";
import Link from "next/link";
import capitalize from "lodash/capitalize";
import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import onWindowResize from "@/helpers/onWindowResize";

export const TAG_CONTAINER_MAX_HEIGHT = 96;

export default function TagList({ tags, markedTag, categorySlug }) {
	const tagContainerRef = useRef(null);

	const showMorerRef = useRef(null);
	const [showMoreOpen, setShowMoreOpen] = useState(false);
	const [showMore, setShowMore] = useState(false);

	useEffect(() => {
		if (tagContainerRef.current) {
			const resize = () => {
				if (tagContainerRef.current) {
					tagContainerRef.current.style.overflowY = "visible";
					tagContainerRef.current.style.maxHeight = "none";

					const tags = [...tagContainerRef.current.querySelectorAll(`[data-type="tag"]`)];
					tags.forEach((tag) => void (tag.style.display = "flex"));

					const showMore = tagContainerRef.current.offsetHeight > TAG_CONTAINER_MAX_HEIGHT;

					if (!showMoreOpen) {
						for (let i = tags.length - 1; i >= 0; i--) {
							if (tagContainerRef.current.offsetHeight <= TAG_CONTAINER_MAX_HEIGHT) {
								break;
							}
							const tag = tags[i];
							tag.style.display = "none";

							if (showMorerRef.current) {
								showMorerRef.current.style.display = "flex";
							}
						}
					}

					setShowMore(showMore);
				}
			};

			resize(); // Initial resize

			return onWindowResize(resize);
		}
	}, [showMore, showMoreOpen, tags]);

	return (
		<div
			ref={tagContainerRef}
			className="md:flex-wrap md:overflow-x-visible scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white-50 flex flex-row justify-start w-full pt-2 mb-4 overflow-x-auto overflow-y-hidden overflow-y-visible"
			style={{ overflowY: "hidden", maxHeight: `${TAG_CONTAINER_MAX_HEIGHT}px` }}
		>
			{tags.map(({ displayName, name, quizCount }, index) => (
				<div key={index} data-type="tag">
					<Link legacyBehavior href={`/${categorySlug ? categorySlug : "tag"}/${name}/`} prefetch={false}>
						<a title={displayName || capitalize(name)}>
							<div
								className={tailwindCascade(
									"bg-black-10 flex flex-col items-center justify-center px-3.5 py-2 mb-3 mr-1.5 rounded-full relative overflow-hidden group",
									{ "bg-black": markedTag === name }
								)}
							>
								<div className="group-hover:bg-opacity-100 z-1 absolute inset-0 bg-black bg-opacity-0" />
								<div
									className={`whitespace-nowrap group-hover:text-white font-bold ${
										markedTag === name ? "text-white" : "text-black"
									} z-2 block text-sm leading-none capitalize`}
								>
									<span>{displayName || name}</span>
									<span
										className={`ml-1 text-xsm group-hover:text-white ${
											markedTag === name ? "text-white" : "text-black-50"
										}`}
									>
										({quizCount})
									</span>
								</div>
							</div>
						</a>
					</Link>
				</div>
			))}
			<div
				ref={showMorerRef}
				className={tailwindCascade(
					"hidden",
					"flex-col",
					"items-center",
					"justify-center",
					"py-2",
					"mb-4",
					"ml-2",
					"mr-4",
					{
						"md:flex": showMore,
						"md:hidden": !showMore,
					}
				)}
			>
				<button
					className="whitespace-nowrap text-petrol font-roboto block text-sm font-bold leading-snug underline capitalize"
					onClick={() => setShowMoreOpen((showMoreOpen) => !showMoreOpen)}
				>
					{showMoreOpen ? trans("Show less...") : trans("Show more...")}
				</button>
			</div>
		</div>
	);
}
